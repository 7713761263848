/*!

 =========================================================
 * Material Dashboard Dark PRO - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-dark-pro
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

 // Bootstrap functions
 @import 'material-dashboard/bootstrap/functions';

 // Theme Variables
 @import 'material-dashboard/variables';

 // Bootstrap core
 @import 'material-dashboard/bootstrap/bootstrap';

 // Theme core
 @import 'material-dashboard/dashboard';
