@mixin sidebar-background-color($background-color, $font-color) {
  .nav {
    .nav-item {
      .nav-link {
        color: $font-color;
      }

      i {
        color: rgba($font-color, .8);
      }

      &.active,
      &:hover {
        [data-toggle="collapse"] {
          color: $font-color;

          i {
            color: rgba($font-color, .8);
          }
        }
      }
    }
  }

  .user {
    a {
      color: $font-color;
    }
  }

  .simple-text {
    color: $font-color;
  }

  .sidebar-background:after {
    background: $background-color;
    opacity: .94;
  }
}
@mixin sidebar-active-color($font-color) {
  .nav {
    .nav-item {
      &.active > a:not([data-toggle="collapse"]) {
        color: $font-color;
        opacity: 1;
        @include shadow-big-color($font-color);

        i {
          color: rgba($font-color, .8);
        }
      }
    }
  }
}
@mixin set-background-color-button($color) {
  li.active > a {
    background-color: $color;
    @include shadow-big-color($color);
  }
}
