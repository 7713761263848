//
// Modals
// Material Design element Dialogs
// --------------------------------------------------

.modal-dialog {
  & .modal-content {
    @include shadow-z-5();
    border-radius: $border-radius-lg;
    border: none;

    .card-signup {
      margin: 0;

      .modal-header {
        padding-top: 0;
      }
    }
  }

  .close:focus {
    outline: none;
  }
  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    border-bottom: none;
    padding: 24px 24px 0;

    .modal-title {
      text-align: center;
      width: 100%;
    }

    .close {
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }
  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    padding: 24px 24px 16px;

    p {
      color: $title-color;
    }
  }
  // Footer (for actions)
  .modal-footer {
    border-top: none;
    padding: 24px;

    &.text-center {
      text-align: center;
    }

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;

      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }
  }

  .modal-title {
    color: $white;
  }

  .modal-body + .modal-footer {
    padding-top: 0;
  }
}

.modal-backdrop {
  background: rgba(0,0,0,0.3);
}

.modal {
  .modal-dialog {

    &.modal-login {
      margin-top: 30vh;
      width: 360px;

      .modal-header {
        .close {
          color: $white;
          text-shadow: none;
          position: absolute;
        }
      }

      .modal-body {
        padding-top: 0;
      }
    }
  }

  .modal-header .close {
    color: $gray-light;

    &:focus,
    &:hover {
      opacity: 1;
    }

    i {
      font-size: 16px;
    }
  }
}

.modal-notice {
  .instruction {
    margin-bottom: 25px;
  }

  .picture {
    max-width: 150px;
  }

  .modal-content {
    .btn-raised {
      margin-bottom: 15px;
    }
  }
}

.modal-small {
  width: 300px;
  margin: 0 auto;

  .modal-content {
    margin-top: 20vh;
  }

  .modal-body {
    margin-top: 20px;
  }
}
