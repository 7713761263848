.card {
  .iframe-container {
    iframe {
      width: 100%;
      height: $icon-iframe-height;
    }
  }
}

a .material-icons {
  vertical-align: middle;
}
