// INPUT - Form Control

$dark-form-contorl-bg-image:              linear-gradient(0deg, #9c27b0 2px, rgba(156,39,176,0) 0),
                                          linear-gradient(0deg, hsla(0,0%,71%,.1) 1px, hsla(0,0%,71%,0) 0) !default;
$md-form-label-color:                    unquote('#a9afbbd1') !default;

$md-input-placeholder-color:             #AAAAAA !default;
$md-input-underline-color:               #D2D2D2 !default;

$md-input-font-size-base:                14px !default;
$md-input-font-size-large:               ceil(($font-size-base * 1.25)) !default; // ~20px
$md-input-font-size-small:               ceil(($font-size-base * 0.75)) !default; // ~12px

$md-md-label-static-size-ratio:         75 / 100 !default;
$md-help-size-ratio:                     75 / 100 !default;

$md-form-control-bg-repeat-y:            no-repeat !default;
$md-form-control-bg-position:            center bottom, center calc(100% - 1px) !default;
$md-form-control-bg-size:                0 100%, 100% 100% !default;
$md-form-control-bg-size-active:         100% 100%, 100% 100% !default;
$md-line-height:                         1.42857143 !default;

// Buttons:
$md-btn-font-size:                       .875rem !default; // 14px
$md-btn-font-size-lg:                    1.25rem !default;
$md-btn-font-size-sm:                    .6875rem !default; // 11px
$md-btn-margin-bottom:                   .3125rem !default; // 5px

// Animations

$md-animation-curve-fast-out-slow-in:    cubic-bezier(0.4, 0, 0.2, 1) !default;
$md-animation-curve-linear-out-slow-in:  cubic-bezier(0, 0, 0.2, 1) !default;
$md-animation-curve-fast-out-linear-in:  cubic-bezier(0.4, 0, 1, 1) !default;
$md-animation-curve-default:             $md-animation-curve-fast-out-slow-in !default;
$md-animation-dropdown-caret:            150ms !default;
$general-transition-time:                 300ms !default;

$slow-transition-time:                    370ms !default;
$fast-transition-time:                    150ms !default;

$transition-linear:                       linear !default;
$transition-bezier:                       cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-bezier-rotating-card:         cubic-bezier(0.34, 1.45, 0.7, 1) !default;
$transition-ease:                         ease 0s;

// sizing

// set as 1x font-size so that padding is easier calculated to match the spec.
$md-form-line-height:                    1 !default;
$md-label-top-margin-base:               1rem !default;
// set as 1x font-size so that padding is easier calculated to match the spec.
$md-form-line-height-lg:                 1 !default;
$md-label-top-margin-lg:                 1rem !default; // 16px
// set as 1x font-size so that padding is easier calculated to match the spec.
$md-form-line-height-sm:                 1 !default;
$md-label-top-margin-sm:                 .75rem !default; // 12px

$md-font-weight-base:                    400;

// wondering if any of these could still be refactored out, but are definitely in use.
$md-inverse:                             rgba($white, 1) !default;
$md-inverse-light:                       rgba($white, 0.84) !default;
$md-inverse-lighter:                     rgba($white, 0.54) !default;

$md-label-color:                         $gray-color !default;
$md-label-color-inner-focus:             $gray !default; // e.g. radio label or text-muted not a control-label which is primary

$md-label-color-focus:                   $primary !default;
$md-invalid-underline:                   $danger !default;
$md-readonly-underline:                  $input-border-color !default;

//---
// verified in use with refactoring to v4

//---
//-- unverified below here
$md-brand-inverse:                       $indigo !default;
// Typography elements FIXME: review to see if we actually need these
$icon-color:                              rgba($black, 0.5) !default;

$md-label-color-focus:                   $primary !default;
$md-invalid-underline:                   $danger !default;
$md-readonly-underline:                  $input-border-color !default;


// Sizing
$md-form-line-height:                    1 !default; // set as 1x font-size so that padding is easier calculated to match the spec.
$md-label-top-margin-base:               1rem !default;
