.info {
  max-width: 360px;
  margin: 0 auto;
  padding: 70px 0 30px;

  .icon {
    color: $white;

    > i {
      font-size: 3.85rem;
    }
  }

  .info-title {
    color: $title-color;
    margin: 0.875rem * 2 0 0.875rem;
  }

  p {
    color: $category-color;
  }
}

.info-horizontal {
  .icon {
    float: left;
    margin-top: 24px;
    margin-right: 10px;

    > i {
      font-size: $h2-font-size;
    }
  }

  .description {
    overflow: hidden;
  }
}

.icon {
  &.icon-primary {
    color: $primary;
  }

  &.icon-info {
    color: $info;
  }

  &.icon-success {
    color: $success;
  }

  &.icon-warning {
    color: $warning;
  }

  &.icon-danger {
    color: $danger;
  }

  &.icon-rose {
    color: $rose;
  }
}
