.dark-edition {
  .form-control {
    // The border bottom should be static in all states, the decorator will be animated over this.
    &:focus,
    .md-form-group.is-focused & {
      background-size: $md-form-control-bg-size-active;
      transition-duration: $checkbox-animation-check;
      box-shadow: none;
    }
    @include material-placeholder() {
      color: $md-input-placeholder-color;
      font-weight: 400;
      font-size: $md-input-font-size-base;
    }

    .has-white & {
      @include material-placeholder() {
        color: $white;
      }
    }
  }


  .input-group-text {
    color: $input-group-text-color;
  }
}

.form-control {
  background: $md-form-control-bg-repeat-y $md-form-control-bg-position;
  background-size: $md-form-control-bg-size;
  transition: $form-control-transition;
}
// Material Design
// -----
// State coloring: default, success, info, warning, danger
//
@include md-selection-color();
@include md-form-color($md-label-color, $md-label-color-focus, $input-border-color, $input-border-color);

.has-success {
  @include md-form-color($success, $success, $success, $input-border-color);
}
.has-danger {
  @include md-form-color($danger, $danger, $danger, $input-border-color);
}

.has-info {
  @include md-form-color($info, $info, $info, $input-border-color);
}

.has-white {
  @include md-form-color($white, $white, $white, $input-border-color-white);

  .form-control {
    &:focus {
      color: $white;
    }
  }
}

.md-form-group {
  position: relative;
  // -----
  // Labels
  //
  // Reference http://www.google.com/design/spec/components/text-fields.html
  // MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
  &:not(.has-success):not(.has-danger) {
    .md-label-floating {
      color: $md-form-label-color;
    }
  }

  .md-label-floating {
    position: absolute;
    pointer-events: none;
    transition: 0.3s ease all;
    will-change: left, top, contents;
    margin: 0;
    line-height: 1.4;
    font-weight: 400;
  }
  // hide label-placeholders when the field is filled
  &.is-filled .md-label-placeholder {
    display: none;
  }
  // Optional class to make the text field inline collapsible/expandable (collapsed by default)
  //    This uses the BS collapse js to make the width expand.
  //    `width` class must also be on the element FIXME: do this with JS, it is a marker class and should be implicit because after all, we are an md-collapse-inline
  //    FIXME: js needs to do the focus on shown.bs.collapse event http://v4-alpha.getbootstrap.com/components/collapse/#events
  &.md-collapse-inline {
    display: flex;
    align-items: center;
    padding: 0; // get rid of any padding as this is a width transition
    min-height: 2.1em;
    // Expandable Holder.
    .collapse {
      flex: 1;
      display: none;

      &.show {
        // This is an unfortunate hack. Animating between widths in percent (%)
        // in many browsers (Chrome, Firefox) only animates the inner visual style
        // of the input - the outer bounding box still 'jumps'.
        // Thus assume a sensible maximum, and animate to/from that value.
        max-width: 1200px;
      }
    }
    .collapsing, .width:not(.collapse),
    // collapsing is removed and momentarily only width is present
    .collapse.show {
      display: block;
    }

    .collapsing {
      @include material-animation-default();
    }
  }
  // default floating size/location with an md-form-group
  @include md-form-size-variant($font-size-base, $md-label-top-margin-base, $input-padding-y, $md-form-line-height, "md-form-group default");
  // sm floating size/location
  &.md-form-group-sm {
    @include md-form-size-variant($font-size-sm, $md-label-top-margin-sm, $input-padding-y-sm, $md-form-line-height-sm, "md-form-group sm");
  }
  // lg floating size/location
  &.md-form-group-lg {
    @include md-form-size-variant($font-size-lg, $md-label-top-margin-lg, $input-padding-y-lg, $md-form-line-height-sm, "md-form-group lg");
  }
}

.form-group {
  padding-bottom: 10px;
  position: relative;
  margin: 8px 0 0;

  .md-label-static {
    top: -10px;
  }
}

.is-filled,
.is-focused {
  .md-label-floating {
    top: -1rem;
    left: 0;
    font-size: $btn-font-size-sm;
  }
}

.form-inline {
  @include media-breakpoint-up(sm) {
    .input-group {
      display: inline-flex;
      align-items: center;
    }
  }
}

.form-control-feedback {
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  opacity: 0;

  .has-success & {
    color: $success;
    opacity: 1;
  }

  .has-danger & {
    color: $danger;
    opacity: 1;
  }
}

.form-group {
  padding-bottom: 10px;
  position: relative;
  margin: 8px 0 0;

  .md-label-static {
    top: -10px;
  }
}

textarea {
  height: auto !important;
  resize: none;
  line-height: $md-line-height !important;
}

.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.form-newsletter {
  .form-group,
  .input-group {
    float: left;
    width: 78%;
    margin-right: 2%;
    margin-top: 9px;
    padding-top: 5px;
  }

  .btn {
    float: left;
    width: 20%;
    margin: 9px 0 0;
  }
}

.form-file-upload {
  .input-group-btn:last-child > .btn-round {
    border-radius: 30px;
  }

  .input-group-btn .btn {
    margin: 0;
  }

  .input-group {
    width: 100%;
  }
}

.input-group .input-group-btn {
  padding: 0 12px;
}

.form-control[disabled],
.form-group .form-control[disabled],
fieldset[disabled] .form-control,
fieldset[disabled] .form-group .form-control {
  background-color: transparent;
  cursor: not-allowed;
  border-bottom: 1px dotted $input-border-color;
  background-repeat: no-repeat;
}

// horizonral forms
.form-inline {
  @include media-breakpoint-up(sm) {
    .input-group {
      display: inline-flex;
      align-items: center;
    }
  }
}
.card {
  .card-body {
    .col-form-label,
    .label-on-right {
      padding: 17px 5px 0 0;
      text-align: right;

      &.label-checkbox {
        padding-top: 13px;
      }
    }

    .label-on-right {
      text-align: left;
    }
  }

  .label-on-right {
    code {
      padding: 3px 7px;
      font-size: 90%;
      color: $white;
      background-color: $dark-background;
      border-radius: 4px;
    }
  }
}

// FORMS EXTEND

form {
  .form-group {
    margin: 8px 0 0;

    label[for="inputState"]{
      position: absolute;
      top: -12px;
    }

    select.form-control {
      position: absolute;
      top: -5px;
    }

    .error {
      font-size: 0.8rem;
      color: $danger;
    }

    .input-group-text {
      padding: $input-group-text-padding;
      color: $input-group-text-color;
    }
  }


  .card {
    .card-footer {
      .form-check {
        margin-left: 7px;
      }
    }
  }

  .checkbox-radios {
    margin-top: 8px;
  }
}


.md-label-static {
  top: -7px !important;
}

.form-check .form-check-label {
  padding-right: 15px;
}

@media all and (max-width: 991px) {
  .card{
    .card-body{
      .col-form-label,
      .label-on-right{
        text-align: left;
        padding-left: 15px;
        padding-top: 8px;
      }
    }
  }
}


.md-form-group.is-focused {
  .md-label-floating {
    top: -0.7rem !important;
  }
}

.card-wizard {
  .input-group .form-group {
    width: 80%
  }
}
