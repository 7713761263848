.footer {
  padding: $padding-general-x 0;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  border-top: 0;

  .copyright,
  ul li a {
    color: $footer-color-links;
  }

  .copyright a {
    color: $white;
  }

  .copyright i {
    animation: heartbeat 1s infinite;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: $heartbeat-color;
    color: transparent;
  }

  ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      a {
        padding: $padding-general-x;
        font-weight: $font-weight-bold;
        font-size: $btn-font-size;
        text-transform: uppercase;
        border-radius: $border-radius;
        text-decoration: none;
        position: relative;
        display: block;

        &:hover {
          text-decoration: none;
        }
      }

      .btn {
        margin: 0;
      }
    }

    &.links-horizontal {
      &:first-child a {
        padding-left: 0;
      }

      &:last-child a {
        padding-right: 0;
      }
    }

    &.links-vertical {
      li {
        display: block;
        margin-left: -$footer-links-value;
        margin-right: -$footer-links-value;

        a {
          padding: $footer-links-value;
        }
      }
    }
  }

  .social-buttons {
    .btn,
    a {
      margin-top: $footer-links-value;
      margin-bottom: $footer-links-value;
    }
  }

  .footer-brand {
    float: left;
    height: $footer-brand-height;
    padding: $footer-brand-padding;
    font-size: $footer-brand-font-size;
    line-height: $footer-brand-line-height;
    margin-left: -$footer-brand-padding;

    &:focus,
    &:hover {
      color: $black-color;
    }
  }

  .copyright {
    padding: $footer-copyright-padding 0;

    .material-icons {
      font-size: 18px;
      position: relative;
      top: 3px;
    }
  }

  .pull-center {
    display: inline-block;
    float: none;
  }
}

.off-canvas-sidebar {
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .footer {
    .copyright {
      padding-right: $footer-copyright-padding;
    }
  }
}


// styling for the dark footer

.footer {
  border-top: 0;

  ul li a,
  .copyright {
    color: $footer-color-links;
  }

  .copyright a {
    color: $white;
  }

  .copyright i {
    animation: heartbeat 1s infinite;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: $heartbeat-color;
    color: transparent;
  }
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}
